import React, {useEffect, useState} from "react";
import {CheckCircle2} from "lucide-react";
import {Alert, AlertDescription, AlertTitle} from "../../components/ui/Alert";

const SuccessPage = () => {
  const [isCoursePayment, setIsCoursePayment] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setIsCoursePayment(queryParams.get("course-payment") === "true");
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8">
        <Alert className="bg-white border-greenC">
          <div className="flex justify-center mb-4">
            <CheckCircle2 className="h-12 w-12 text-green"/>
          </div>
          <AlertTitle className="text-2xl font-semibold text-green text-center">
            Success!
          </AlertTitle>
          <AlertDescription className="mt-2 text-center">
            <p>Thank you for your payment!</p>
            <p>
              {isCoursePayment
                ? "Please check your email inbox for your course purchase."
                : "Return to the app to access your new features."}
            </p>
          </AlertDescription>
          <div className="mt-6 flex justify-center">
            <a href="https://recant.ai">
              <button className="bg-green text-white px-4 py-2 rounded-lg hover:bg-hoverGreen transition-colors">
                Home
              </button>
            </a>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default SuccessPage;