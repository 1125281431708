import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function MouseHoverPopover({ popoverData, title }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <button className='flex w-full md:!w-full items-center gap-2 !justify-center rounded-3xl font-poppins text-[10px] lg:text-sm  text-baseColor'>
          {title} <IoMdInformationCircleOutline fontSize={20} />
        </button>
      </Typography>
      <Popover
        id='mouse-over-popover'
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* Use dangerouslySetInnerHTML for allowing line breaks and HTML content */}
        <Typography
          sx={{ p: 1 }}
          dangerouslySetInnerHTML={{
            __html: popoverData.replace(/\n/g, "<br />"),
          }}
        />
      </Popover>
    </div>
  );
}
