import { WWYMS_URL } from "../../../global";

export default function WWYMSButton() {
  return (
    <a href={WWYMS_URL} className={"no-underline"}>
      <div
        className={` text-sm cursor-pointer font-bold lg:font-normal lg:!text-base font-poppins border-[2px] lg:px-7 sm:px-6 px-3 py-[9px] rounded-[24px] bg-[#020403] text-white `}
      >
        <p className={"md:hidden block"}>WWYMS</p>
        <p className={"md:block hidden"}>What Would Your Mother Say?</p>
      </div>
    </a>
  );
}
