import React from "react";
import MainLayout from "../../common/MainLayout";

const RecantGuide = () => {
  return (
    <MainLayout containerMarginTop='190px'>
      <div className='max-w-4xl mx-auto p-6 font-sans text-gray-800 leading-relaxed'>
        <h1 className='text-center text-3xl font-bold mb-8 text-baseColor'>
          Recant Sign-Up & Installation Guide
        </h1>

        <section className='bg-[#e0e0e0] border border-gray-200 rounded-lg p-6 mb-6'>
          <h2 className='text-xl font-semibold text-baseColor border-b border-baseColor pb-2'>
            Step 1: Sign Up
          </h2>
          <p className='mt-4'>
            <b>Sign up here</b>:{" "}
            <a
              className='cursor-pointer hover:text-baseColor'
              href='https://recant.ai/signup'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://recant.ai/signup
            </a>
          </p>
          <ul className='list-disc list-inside mt-1'>
            <li className='mt-2'>
              After signing up, adjust your <b>sensitivity dials</b> to set your
              tolerance for different content types.
            </li>
            <li className='mt-2'>
              Once on the <b>dashboard</b>, click on <b>Instagram</b>—this will
              direct you to download the <b>desktop app</b> for either{" "}
              <b>Windows</b> or <b>Mac</b>.
            </li>
          </ul>
        </section>

        <section className='bg-[#e0e0e0] border border-gray-200 rounded-lg p-6 mb-6'>
          <h2 className='text-xl font-semibold text-baseColor border-b border-baseColor pb-2'>
            Step 2: Install Recant
          </h2>
          <div className='mt-4'>
            <h3 className='text-lg font-medium text-baseColor mt-4'>
              Mac Users:
            </h3>
            <ol className='list-decimal list-inside mt-2'>
              <li>
                Click on the Apple menu (
                <span role='img' aria-label='Apple menu'>
                  
                </span>
                ) in the top-left corner.
              </li>
              <li>
                Select "<b>About This Mac.</b>"
              </li>
              <li>
                Look for the <b>Chip</b> or <b>processor</b> information:
                <ul className='list-disc list-inside ml-6 mt-1'>
                  <li>
                    If it says <b>"Apple M1", "M2", "M3"</b>, etc., your Mac has
                    <b> Apple Silicon</b>.
                  </li>
                  <li>
                    If it says <b>"Intel"</b>, your Mac has an{" "}
                    <b>Intel processor</b>.
                  </li>
                </ul>
              </li>
              <li>
                <b>Download the relevant version</b> of the app based on your
                Mac type and install it.
              </li>
            </ol>
          </div>
          <div className='mt-6'>
            <h3 className='text-lg font-medium text-baseColor mt-4'>
              Windows Users:
            </h3>
            <ol className='list-decimal list-inside mt-2'>
              <li>
                <b>Download and open</b> the ZIP file.
              </li>
              <li>
                Open the <b>squirrel.windows</b> folder.
              </li>
              <li>
                Open the <b>x64</b> folder.
              </li>
              <li>
                Click on the application file (<b>Recant-1.11.83 Setup</b>) and
                select
                <b>“Extract All”</b>.
              </li>
              <li>You should see the extracted folder open.</li>
              <li>
                Double-click{" "}
                <span className='font-bold'>
                  <b>Recant-1.11.83 Setup.exe</b>
                </span>{" "}
                to start the installation.
              </li>
              <li>
                If a Windows Security Prompt appears, click <b>"Run"</b> or{" "}
                <b>"Yes"</b> to allow installation.
              </li>
            </ol>
            <p className='mt-4 font-semibold'>After Installation:</p>
            <ul className='list-disc list-inside mt-1'>
              <li>
                <b>Start Menu →</b> Search for <b>"Recant"</b>
              </li>
              <li>
                <>Desktop Shortcut</> (if created)
              </li>
              <li>
                <b>Program Files Folder </b>(where it was installed)
              </li>
            </ul>
          </div>
        </section>

        <section className='bg-[#e0e0e0] border border-gray-200 rounded-lg p-6 mb-6'>
          <h2 className='text-xl font-semibold text-baseColor border-b border-baseColor pb-2'>
            Step 3: Accessing Your Messages & Future Features
          </h2>

          <p className='mt-4'>
            Once installed, you will have access to your messages{" "}
            <b>in a private, local environment</b>.
          </p>
          <p className='mt-2'>
            <b>Coming soon:</b> Recant will unify{" "}
            <b>all your messaging and email apps</b>
            into one interface and automate communications using AI agents. We
            welcome any ideas here that might make your life easier.
          </p>
        </section>

        <section className='bg-[#e0e0e0] border border-gray-200 rounded-lg p-6'>
          <h2 className='text-xl font-semibold text-[#0A2B38] border-b border-[#0A2B38] pb-2'>
            Step 4: Enable or Disable Automated Responses to Abusers
          </h2>
          <p className='mt-4'>
            To turn off or on automated replies warning the abusers of potential
            recourse:
          </p>
          <ol className='list-decimal list-inside mt-2'>
            <li>
              Go to <b>Settings</b>.
            </li>
            <li>
              Select <b>Preferences</b>.
            </li>
            <li>
              Toggle <b>"Enable/Disable Automated Responses to Abusers."</b>
            </li>
          </ol>
          <p className='mt-4'>
            Please be aware that the automated replies come with our Freemium
            offering. The reply offers perpetrators an opportunity to Recant
            their messages by paying a small fee. With this fee they subscribe
            to a short behavioural management course – part of the money goes to
            you, part to a charity of your choice. If they reject this offer, we
            provide you with the tools to escalate or make a publication about
            the individual. If you prefer to turn this reply message off or
            craft your own, this falls under our paid model and a subscription
            fee is charged.
          </p>
        </section>
      </div>
    </MainLayout>
  );
};

export default RecantGuide;
