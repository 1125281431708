import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {FaFacebook} from "react-icons/fa";
import {FaPlus} from "react-icons/fa6";
import {toast} from "react-toastify";

const FacebookLoginButton = ({onLoginSuccess, onLoginFailure}) => {
  const responseFacebook = (response) => {
    console.log(response, "response from facebook");
    if (response.accessToken) {
      onLoginSuccess(response);
    } else {
      toast.error("Failed to login with Facebook");
      onLoginFailure();
    }
  };
  return (
    <FacebookLogin
      render={(renderProps) => (
        <button>
          {/*<button onClick={renderProps.onClick}>*/}
          <div
            className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba] hover:cursor-not-allowed">
            <div className="flex items-center justify-between w-full ">
              <FaFacebook className="text-[#34589d]" size={21}/>
              {/*<div className="bg-primaryColor text-white px-1 rounded-[4px] font-zain text-[9px] flex">*/}
              {/*  launching in 2 weeks*/}
              {/*</div>*/}
              {/*  */}
            </div>
            <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10">
              Facebook (Coming Soon)
            </p>
          </div>
        </button>
      )}
      appId={process.env.REACT_APP_FACEBOOK_APPID}
      autoLoad={false}
      fields="name,email,picture"
      scope="instagram_manage_messages,instagram_basic,pages_show_list,business_management,pages_messaging,pages_read_engagement,pages_manage_metadata,read_page_mailboxes"
      callback={responseFacebook}
      cssClass="bg-white flex gap-3 items-center w-full"
      textButton="Connect to Facebook"
      disableMobileRedirect={true}
    />
  );
};

export default FacebookLoginButton;
