import React, { useState } from "react";
import Logo from "../../assets/images/whiteLogo.png";
import { FaWhatsapp, FaPhoneAlt, FaMailBulk } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import PhoneImage from "../../assets/images/PhoneImage.png";
import EmailImage from "../../assets/images/emailImage.png";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ToggleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const svgStyle = {
    width: "17px",
    height: "17px",
    transition: "transform 0.3s ease",
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    transformOrigin: "center",
  };

  return (
    <div className='w-full max-w-md'>
      <div
        onClick={toggleOpen}
        className='flex items-center justify-between cursor-pointer bg-blue-500 text-baseColor rounded-lg p-2'
      >
        <div className='text-sm font-semibold'>{title}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            style={svgStyle}
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M19 9l-7 7-7-7'
            ></path>
          </svg>
        </div>
      </div>
      {isOpen && <div className='text-sm'>{children}</div>}
    </div>
  );
};

const Footer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1024));
  const navigate = useNavigate();
  const handleClick = (type) => {
    if (type === "whatsapp") {
      const phoneNumber = "61480494834";
      const url = `https://wa.me/${phoneNumber}`;
      window.open(url, "_blank");
    }
    if (type === "x") {
      const url = "https://x.com/RecantAi";
      window.open(url, "_blank");
    }
  };
  return (
    <div className='cursor-pointer bg-[#020403] text-white'>
      <div className='max-w-[1440px] mx-auto  px-[15px] lg:px-[60px] md:!px-[90px]'>
        <div className='lg:py-16'>
          <div
            className={`flex flex-col justify-between py-[50px] lg:!flex-row lg:!gap-12 gap-6 items-center`}
          >
            <div className='w-[150px] lg:w-[190px] mb-4'>
              <img
                src={Logo}
                alt='logo'
                className=''
                onClick={() => navigate("/")}
              />
            </div>

            <div className='flex gap-[39px] lg:!gap-24'>
              <div className='flex flex-col lg:items-center'>
                <div className='text-base mb-2 lg:!text-lg font-DMSans font-bold'>
                  Connect With Us
                </div>
                <div className='flex flex-col gap-2 font-zain lg:!text-lg font-[300] '>
                  <div className='flex gap-1 items-center hover:underline'>
                    <div className='flex items-center gap-1'>
                      <FaPhoneAlt color='white' size={15} />
                      +61480494834
                    </div>
                  </div>
                  <div className='flex gap-1 items-center hover:underline'>
                    <div className='flex items-center gap-1'>
                      <FaMailBulk color='white' size={15} />
                      cam@recant.ai
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-base mb-2 lg:!text-lg font-DMSans font-bold'>
                  Policies
                </div>
                <div className='flex flex-col items-start'>
                  <button
                    onClick={() => navigate("/privacy-policy")}
                    className='hover:underline font-zain lg:!text-lg font-[300] '
                  >
                    Privacy Policy
                  </button>
                  <button
                    onClick={() => navigate("/faqs")}
                    className='hover:underline font-zain lg:!text-lg font-[300] '
                  >
                    FAQs
                  </button>
                </div>
              </div>
              <div className='w-[20px] '>
                <FaWhatsapp
                  className='mb-10'
                  color='white'
                  size={25}
                  onClick={() => handleClick("whatsapp")}
                />
                <BsTwitterX
                  color='white'
                  size={20}
                  onClick={() => handleClick("x")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='pb-[15px] text-lg text-[#223443] font-[300] font-zain lg:!text-xl flex items-center justify-center text-white'>
          © 2024 Recant.ai All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
