import React, { useState } from "react";
import AdminLayout from "./AdminLayout";
import { apiClient } from "../../config/axios";
import { useQuery } from "react-query";
import {
  Avatar,
  TablePagination,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

export const fetchInterceptedMessage = async () => {
  const response = await apiClient.get(
    `api/message-interception/admin/message-intercepted`
  );
  return response;
};

const AdminInterceptedMessage = () => {
  const {
    data: interceptedMessage,
    isLoading,
    error,
  } = useQuery("[InterceptedMessageList]", () => fetchInterceptedMessage());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: "message", align: "left", label: "Message" },
    { id: "platform", align: "center", label: "Platform" },
  ];

  return (
    <AdminLayout>
      <div className=''>
        <div className='flex justify-between mb-[40px] items-center'>
          <h4 className='font-bold  textSecondary'>Intercepted Message List</h4>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 600 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {interceptedMessage?.data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell padding='checkbox'></TableCell>
                    <TableCell align='left'>
                      <Typography variant='subtitle1'>
                        {user.message}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>{user.platform}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={interceptedMessage?.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </AdminLayout>
  );
};

export default AdminInterceptedMessage;
