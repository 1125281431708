import React from "react";

export default function DesktopDownloadButton({onClick, className, children}) {
  return (
    <button
      className={`rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center px-3  w-full hover:border-[#bcbaba] ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}