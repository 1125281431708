import React, {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import {useForm} from "react-hook-form";
import {apiClient} from "../../config/axios";
import RangeSlider from "../../common/RangeSlider";

const SensitivityParameter = forwardRef((props, ref) => {
  const {isEdit} = props;
  const {handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);
  const [sliders, setSliders] = useState([
    {label: "Sexually Suggestive/Explicit", key: "sexuallyExplicit", value: 5},
    {label: "Threatening/Violent Content", key: "threateningOrViolent", value: 5},
    {label: "Racism", key: "racist", value: 5},
    {label: "Sexism", key: "sexist", value: 5},
    {label: "General Hate", key: "generallyHateful", value: 5},
  ]);

  useEffect(() => {
    const fetchSensitivityParameters = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get("/api/sensitivity-parameters");
        if (response.data.success && response.data.parameters) {
          const updatedSliders = sliders.map(slider => {
            const apiValue = response.data.parameters[slider.key];
            return apiValue !== undefined ? {...slider, value: apiValue} : slider;
          });
          setSliders(updatedSliders);
        }
      } catch (error) {
        console.error("Failed to fetch sensitivity parameters", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSensitivityParameters();
  }, []);

  const onSetParameterSubmit = async () => {
    setLoading(true);
    try {
      const payload = sliders.reduce((acc, slider) => {
        acc[slider.key] = slider.value;
        return acc;
      },);
      const response = await apiClient.post("/api/sensitivity-parameters", payload);
      if (response.data.success) {
        console.log("Sensitivity parameters updated successfully");
      }
    } catch (error) {
      console.error("Failed to update sensitivity parameters", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSliderChange = (index, newValue) => {
    setSliders(prev =>
      prev.map((slider, i) =>
        i === index ? {...slider, value: Number(newValue)} : slider
      )
    );
  };

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSetParameterSubmit)(),
    getSliders: () =>
      sliders.reduce((acc, slider) => {
        acc[slider.key] = slider.value;
        return acc;
      },),
  }));

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit(onSetParameterSubmit)}>
        <div>
          <div className="mb-6 font-bold text-[15px]">
            Please select your overall sensitivity level towards the following subjects.
          </div>
          <div className="flex flex-col gap-3">
            {sliders.map((slider, index) => (
              <div key={index} className="flex flex-col relative gap-1 items-start">
                <div className="mb-1 text-[14px]">{slider.label}</div>
                <RangeSlider
                  isEdit={isEdit}
                  className="w-full text-primaryColor"
                  color="#0A2B38"
                  tooltipPlacement="top"
                  min={0}
                  max={10}
                  value={slider.value}
                  onChange={(e) => handleSliderChange(index, e.target.value)}
                  disabled={loading}
                />
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
});

export default SensitivityParameter;