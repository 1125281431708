import React, { useState } from "react";
import AdminLayout from "./AdminLayout";
import { apiClient } from "../../config/axios";
import { useQuery } from "react-query";
import {
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
} from "@mui/material";
import { format } from "date-fns";
import { z } from "zod";

// Schema for coupon validation using Zod
const couponSchema = z.object({
  code: z.string({ required_error: "Coupon Code is required" }).min(3),
  discountType: z.enum(["percentage", "fixed"], {
    errorMap: () => ({ message: "Discount Type is required" }),
  }),
  discountAmount: z.coerce
    .number()
    .positive({ message: "Must be a positive number" }),
  expirationDate: z.string().refine((val) => !isNaN(new Date(val).getTime()), {
    message: "Invalid date",
  }),
  maxUsage: z.coerce.number().positive(),
  maxUsagePerUser: z.coerce.number().positive().optional(),
});

const fetchCouponsList = async () => {
  const { data } = await apiClient.get("api/coupons/admin");
  return data;
};

const createCoupon = async (couponData) => {
  const { data } = await apiClient.post("api/coupons/admin", couponData);
  return data;
};

const AdminCoupons = () => {
  const { data, isLoading, error, refetch } = useQuery(
    "coupons",
    fetchCouponsList
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [newCoupon, setNewCoupon] = useState({
    code: "",
    discountType: "percentage",
    discountAmount: "",
    maxUsage: 10,
    expirationDate: "",
    maxUsagePerUser: "",
  });

  const headCells = [
    { id: "code", label: "Code" },
    { id: "discountType", label: "Type", align: "center" },
    { id: "discountAmount", label: "Amount", align: "center" },
    { id: "isActive", label: "Status", align: "center" },
    { id: "usedCount", label: "Used", align: "center" },
    { id: "expirationDate", label: "Expires", align: "center" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError("");

    const validation = couponSchema.safeParse(newCoupon);
    if (!validation.success) {
      setFormErrors(validation.error.flatten().fieldErrors);
      return;
    }

    try {
      await createCoupon(validation.data);
      await refetch();
      setModalOpen(false);
      setNewCoupon({
        code: "",
        discountType: "discountType",
        discountAmount: "",
        maxUsage: 10,
        expirationDate: "",
        maxUsagePerUser: "",
      });
      setFormErrors({});
    } catch (err) {
      setApiError(err.response?.data?.message || "Failed to create coupon");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setNewCoupon((prev) => ({
      ...prev,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  if (isLoading)
    return (
      <AdminLayout>
        <CircularProgress sx={{ mt: 4 }} />
      </AdminLayout>
    );

  if (error)
    return (
      <AdminLayout>
        <Alert severity='error'>Error loading coupons: {error.message}</Alert>
      </AdminLayout>
    );

  return (
    <AdminLayout>
      <div className='p-6'>
        <div className='flex justify-between mb-6'>
          <Typography variant='h5'>Coupon Management</Typography>
          <Button
            variant='contained'
            onClick={() => setModalOpen(true)}
            sx={{ borderRadius: 2 }}
          >
            New Coupon
          </Button>
        </div>

        {apiError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {apiError}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <TableCell key={cell.id} align={cell.align || "left"}>
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((coupon) => (
                    <TableRow key={coupon.id}>
                      <TableCell>{coupon.code}</TableCell>
                      <TableCell align='center'>
                        {coupon.discountType}
                      </TableCell>
                      <TableCell align='center'>
                        {coupon.discountType === "percentage"
                          ? `${coupon.discountAmount}%`
                          : `$${coupon.discountAmount}`}
                      </TableCell>
                      <TableCell align='center'>
                        <Chip
                          label={coupon.isActive ? "Active" : "Expired"}
                          color={coupon.isActive ? "success" : "error"}
                          size='small'
                        />
                      </TableCell>
                      <TableCell align='center'>{coupon.usedCount}</TableCell>
                      <TableCell align='center'>
                        {format(
                          new Date(coupon.expirationDate),
                          "MM/dd/yyyy HH:mm"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headCells.length} align='center'>
                    No coupons found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, p) => setPage(p)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle>Create New Coupon</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit} className='space-y-4 mt-2'>
              <TextField
                fullWidth
                label='Coupon Code'
                name='code'
                value={newCoupon.code}
                onChange={handleInputChange}
                error={!!formErrors.code}
                helperText={formErrors.code?.[0]}
                required
              />

              <FormControl fullWidth error={!!formErrors.discountType}>
                <InputLabel>Discount Type</InputLabel>
                <Select
                  name='discountType'
                  value={newCoupon.discountType}
                  onChange={handleInputChange}
                  label='Discount Type'
                >
                  <MenuItem value='percentage'>Percentage</MenuItem>
                  <MenuItem value='fixed'>Fixed Amount</MenuItem>
                </Select>
                {formErrors.discountType && (
                  <Typography variant='caption' color='error'>
                    {formErrors.discountType[0]}
                  </Typography>
                )}
              </FormControl>

              <TextField
                fullWidth
                type='number'
                label={`Discount ${newCoupon.discountType === "percentage" ? "Percentage" : "Fixed Amount"}`}
                name='discountAmount'
                value={newCoupon.discountAmount}
                onChange={handleInputChange}
                error={!!formErrors.discountAmount}
                helperText={formErrors.discountAmount?.[0]}
                inputProps={{ min: 1 }}
                required
              />

              <TextField
                fullWidth
                type='datetime-local'
                label='Expiration Date'
                name='expirationDate'
                value={newCoupon.expirationDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.expirationDate}
                helperText={formErrors.expirationDate?.[0]}
                required
              />

              <TextField
                fullWidth
                type='number'
                label='Maximum Uses'
                name='maxUsage'
                value={newCoupon.maxUsage}
                onChange={handleInputChange}
                error={!!formErrors.maxUsage}
                helperText={formErrors.maxUsage?.[0]}
                inputProps={{ min: 1 }}
                required
              />

              <TextField
                fullWidth
                type='number'
                label='Max Uses Per User'
                name='maxUsagePerUser'
                value={newCoupon.maxUsagePerUser}
                onChange={handleInputChange}
                error={!!formErrors.maxUsagePerUser}
                helperText={formErrors.maxUsagePerUser?.[0]}
                inputProps={{ min: 1 }}
              />

              <DialogActions sx={{ mt: 3 }}>
                <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                <Button type='submit' variant='contained' color='primary'>
                  Create Coupon
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </AdminLayout>
  );
};

export default AdminCoupons;
