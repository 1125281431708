import { useNavigate } from "react-router-dom";

const JoinBetaButton = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button
        onClick={() => navigate("/signup")}
        className={` text-sm   cursor-pointer font-bold lg:font-normal lg:!text-base font-poppins border-[2px] bg-[#020403] lg:px-7 sm:px-6 px-3 py-[9px] rounded-[24px]  text-white `}
      >
        <p className={"md:hidden block"}>Beta</p>
        <p className={"md:block hidden"}>Join Beta</p>
      </button>
    </div>
  );
};

export default JoinBetaButton;
