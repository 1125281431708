import Intercom from "@intercom/messenger-js-sdk";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import {
  SignUp,
  Login,
  Verified,
  ForgotPassword,
  ResetPassword,
} from "./components/Auth";
import Dashboard from "./components/Dashboard/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import {
  MessageIntercepted,
  PageNotFound,
  SupportUser,
  TermsCondition,
  SensitivityParameter,
  MessageAllegation,
  DoNotTrust,
  PaymentSummary,
  FaceBookAddPage,
  FaceBookConnectedProfile,
  InstagramConnectedProfile,
  TwitterConnectedProfile,
  FacebookMessages,
  InstagramMessages,
  TwitterMessages,
  Home,
  UserProfile,
  UserList,
  AdminWaitingList,
  AdminWaitingListDetail,
  AdminProfile,
  PaymentSuccessful,
  MessageInterceptTrust,
  DenyAllegation,
  AboutUs,
  LandingPage,
  ReportWarnedMember,
  AdminInterceptedMessage,
  InterceptedMessageDetails,
  GenerateResponse,
  GeneratedManualReply,
  ReportToWWYMS,
  UploadTroll,
  UserInitiatedMessageFlagged,
  WhyAmIGettingThis,
  SuccessPage,
  ErrorPage,
  FAQs,
  RecantGuide,
} from "./pages";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ProtectedRoute } from "../src/utils/ProtectedRoute";
import {
  PrivacyPolicy,
  WaitingList,
  Payment,
  UserInformation,
} from "../src/components/index";
import { useAuth } from "./config/useAuth";
import { UserProvider } from "./components/DataContext";
import VerifyEmail from "./components/Auth/VerifyEmail";
import CheckoutUserList from "./pages/Admin/Checkout";
import CheckoutUserDetail from "./pages/Admin/CheckoutDetails";
import InfluencerInformation from "./components/InfluncerInformation";
import PrivateInstagram from "./components/PrivateInstagram";
import PrivateInstagramConnectedProfile from "./pages/InstagramProfile";
import PrivateInstagramMessages from "./pages/PrivateInstagramMessages";
import TwoFactorAuthentication from "./components/TwoFactorAuthentication";
import InstagramSecurityForm from "./components/InstagramSecurityForm";
import CustomizedStepper from "./pages/StartStep";
import ReportedTrollList from "./pages/Admin/trollList";
import ReportedTrollListDetails from "./pages/Admin/TrollListDetails";
import Research from "./pages/Research";
import AdminCoupons from "./pages/Admin/Coupons";

function App() {
  const user = useAuth();

  Intercom({
    app_id: "lmdb7o6i",
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: user.createdAt,
  });
  const router = createBrowserRouter([
    {
      path: "/login",

      element: user ? <Navigate to='/dashboard' /> : <Login />,
    },
    {
      path: "/signup",
      element: user ? <Navigate to='/dashboard' /> : <SignUp />,
    },

    {
      path: "/payment-summary",
      element: <PaymentSummary />,
    },
    {
      path: "/payment-success",
      element: <SuccessPage />,
    },
    {
      path: "/payment-cancelled",
      element: <ErrorPage />,
    },
    {
      path: "/404",
      element: <PageNotFound />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/payment-received",
      element: <PaymentSuccessful />,
    },
    {
      path: "/waiting-list",
      element: <WaitingList />,
    },
    {
      path: "/individual-survey",
      element: <InfluencerInformation />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/message-intercepted",
      element: <MessageIntercepted />,
    },
    {
      path: "/message-flagged",
      element: <UserInitiatedMessageFlagged />,
    },

    {
      path: "/terms",
      element: <TermsCondition />,
    },
    {
      path: "/message-filter",
      element: <MessageInterceptTrust />,
    },
    {
      path: "/not-trust",
      element: <DoNotTrust />,
    },
    {
      path: "/message-allegation",
      element: <MessageAllegation />,
    },
    {
      path: "/response-allegation",
      element: <DenyAllegation />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/verified",
      element: <Verified />,
    },
    {
      path: "/verify",
      element: <VerifyEmail />,
    },
    {
      path: "/payment-checkout",
      element: <Payment />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/upload-troll",
      element: <UploadTroll />,
    },
    {
      path: "/generate-response",
      element: <GenerateResponse />,
    },
    {
      path: "/generated-manual-reply",
      element: <GeneratedManualReply />,
    },
    {
      path: "/why-am-i-getting-this",
      element: <WhyAmIGettingThis />,
    },
    {
      path: "/faqs",
      element: <FAQs />,
    },
    {
      path: "/research",
      element: <Research />,
    },
    {
      path: "/instructions",
      element: <RecantGuide />,
    },
    {
      key: "protected",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/facebook/addpage",
          element: <FaceBookAddPage />,
        },
        {
          path: "/twitter/profiles",
          element: <TwitterConnectedProfile />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/twitter/:platformId/messages",
          element: <TwitterMessages />,
        },
        {
          path: "/facebook/business-profile",
          element: <FaceBookConnectedProfile />,
        },
        {
          path: "/instagram/business-profile",
          element: <InstagramConnectedProfile />,
        },
        {
          path: "/instagram/messages/:businessId",
          element: <InstagramMessages />,
        },
        {
          path: "/facebook/messages/:businessId",
          element: <FacebookMessages />,
        },
        {
          path: "/user-information",
          element: <UserInformation />,
        },
        {
          path: "/support",
          element: <SupportUser />,
        },
        {
          path: "/preferences",
          element: <SensitivityParameter />,
        },
        {
          path: "/admin-user",
          element: <UserList />,
        },
        {
          path: "/admin-profile",
          element: <AdminProfile />,
        },
        {
          path: "/admin-checkout",
          element: <CheckoutUserList />,
        },
        {
          path: "/admin-intercepted-message",
          element: <AdminInterceptedMessage />,
        },
        {
          path: "/admin-coupons",
          element: <AdminCoupons />,
        },
        {
          path: "/admin",
          element: <Home />,
        },
        {
          path: "/admin-report-to-wwyms",
          element: <ReportWarnedMember />,
        },
        {
          path: "/admin-user/:id",
          element: <UserProfile />,
        },
        {
          path: "/admin-checkout/:id",
          element: <CheckoutUserDetail />,
        },
        {
          path: "/admin-intercepted-message/:id",
          element: <InterceptedMessageDetails />,
        },
        {
          path: "/admin-waiting-list",
          element: <AdminWaitingList />,
        },
        {
          path: "/admin-waiting-list/:id",
          element: <AdminWaitingListDetail />,
        },
        {
          path: "/connect-instagram",
          element: <PrivateInstagram />,
        },
        {
          path: "/instagram/profiles",
          element: <PrivateInstagramConnectedProfile />,
        },
        {
          path: "/instagram/:businessId/messages",
          element: <PrivateInstagramMessages />,
        },
        {
          path: "/instagram-2fa",
          element: <TwoFactorAuthentication />,
        },
        {
          path: "/instagram-verify",
          element: <InstagramSecurityForm />,
        },
        {
          path: "/account-setup",
          element: <CustomizedStepper />,
        },
        {
          path: "/report-to-wwyms",
          element: <ReportToWWYMS />,
        },
        {
          path: "/admin-reported-troll-list",
          element: <ReportedTrollList />,
        },
        {
          path: "/admin-reported-troll-list",
          element: <ReportedTrollList />,
        },
        {
          path: "/admin-reported-troll-list/:id",
          element: <ReportedTrollListDetails />,
        },
      ],
    },
  ]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </UserProvider>
    </>
  );
}

export default App;
