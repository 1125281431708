import React, {useState, useEffect} from "react";
import {PiAppleLogoFill, PiWindowsLogoFill, PiAndroidLogoFill, PiLinuxLogoFill} from "react-icons/pi";
import DesktopDownloadButton from "../DesktopDownloadButton";
import BeeperComponent from "../BeeperComponent";

export default function DesktopDownloadSection() {
  const [openBeeperModal, setOpenBeeperModal] = useState(false);
  const [isSiliconMac, setIsSiliconMac] = useState(false);
  const [osType, setOsType] = useState('unknown');

  // Detect OS and architecture on component mount
  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Detect operating system
    if (userAgent.indexOf("Win") !== -1) {
      setOsType('windows');
    } else if (userAgent.indexOf("Mac") !== -1) {
      setOsType('mac');

      // Attempt to detect Apple Silicon
      const detectAppleSilicon = () => {
        try {
          const canvas = document.createElement('canvas');
          const gl = canvas.getContext('webgl');
          if (!gl) return false;

          const extension = gl.getExtension('WEBGL_debug_renderer_info');
          if (!extension) return false;

          const renderer = gl.getParameter(extension.UNMASKED_RENDERER_WEBGL);

          // Apple GPUs in M1/M2/etc. will have "Apple" in the renderer string
          if (renderer && renderer.toLowerCase().includes('apple')) {
            return true;
          }

          // Check for high core count as secondary indicator
          if (navigator.hardwareConcurrency > 8) {
            return true;
          }

          return false;
        } catch (e) {
          return false;
        }
      };

      setIsSiliconMac(detectAppleSilicon());
    } else if (userAgent.indexOf("Linux") !== -1) {
      setOsType('linux');
    } else if (userAgent.indexOf("Android") !== -1) {
      setOsType('android');
    } else if (userAgent.indexOf("iPhone") !== -1 || userAgent.indexOf("iPad") !== -1) {
      setOsType('ios');
    }
  }, []);

  const handleCloseModal = () => {
    setOpenBeeperModal(false);
  };

  const downloadPackage = (build) => {
    const packageURL = `https://recant-desktop-packages.s3.ap-southeast-2.amazonaws.com/recant-desktop-${build}.zip`;

    const link = document.createElement("a");
    link.href = packageURL;
    link.download = "recant-desktop";
    link.click();
  };

  // Auto-select the right Mac package based on detected architecture
  const downloadMacPackage = () => {
    const build = isSiliconMac ? "macos-amd64" : "macos-universal";
    downloadPackage(build);
    setOpenBeeperModal(true);
  };

  return (
    <div>
      <div className="mb-8 mt-6">
        {/*<p className="text-lg text-grey text-center">*/}
        {/*  Manage your conversations effortlessly with the Recant App (Beta).*/}
        {/*</p>*/}
      </div>

      <div className="grid grid-cols-2 gap-8">
        <DesktopDownloadButton
          onClick={downloadMacPackage}
        >
          <p
            className="text-lg text-gray-600 font-medium ml-4 mb-0 px-2 pt-4 pb-4 flex flex-row justify-center items-center gap-2">
            <PiAppleLogoFill/>
            <span>macOS</span>
          </p>
        </DesktopDownloadButton>

        <DesktopDownloadButton
          onClick={() => {
            downloadPackage("windows");
            setOpenBeeperModal(true);
          }}
        >
          <p
            className="text-lg text-gray-600 font-medium ml-4 mb-0 px-2 pt-4 pb-4 flex flex-row justify-center items-center gap-2">
            <PiWindowsLogoFill/>
            <span>Windows</span>
          </p>
        </DesktopDownloadButton>

        <DesktopDownloadButton
          onClick={() => {
            downloadPackage("linux");
            setOpenBeeperModal(true);
          }}
        >
          <p
            className="text-lg text-gray-600 font-medium ml-4 mb-0 px-2 pt-4 pb-4 flex flex-row justify-center items-center gap-2">
            <PiLinuxLogoFill/>
            <span>Linux</span>
          </p>
        </DesktopDownloadButton>

        <DesktopDownloadButton
          className="hover:cursor-not-allowed"
        >
          <p className="text-lg font-medium ml-4 mb-0 px-2 pt-4 pb-4 flex flex-row justify-center items-center gap-2"
             style={{color: '#9ca3af'}}>
            <PiAppleLogoFill/>
            <PiAndroidLogoFill/>
            <span>iOS/Android (In Development)</span>
          </p>
        </DesktopDownloadButton>
      </div>

      <BeeperComponent
        showModal={openBeeperModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}