import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {FaSquareXTwitter} from "react-icons/fa6";
import {ImLinkedin2} from "react-icons/im";
import {FaFacebook} from "react-icons/fa";
import {useQueryClient} from "react-query";
import {apiClient} from "../../config/axios";
import Loader from "../../components/Loader";
import Instagram from "../../components/Instagram/instagram";
import {instagramIcon} from "../../assets/icons/icons";
import TwitterLoginButton from "../../components/Twitter/twitter-login";
import FacebookLoginButton from "../../components/Facebook/facebook-login";
import {useTwitterProfileStatus} from "../../hooks/useTwitterProfileStatus";
import {useFacebookProfileStatus} from "../../hooks/useFacebookProfileStatus";
import {useInstagramProfileStatus} from "../../hooks/useInstagramProfileStatus";
import DesktopDownloadSection from "../DesktopDownloadSection";

const SocialMediaConnection = () => {
  const navigate = useNavigate();
  const [connectLinkedinStatus] = useState(false);
  const token = localStorage.getItem("token");
  const {data: connectTwitterStatus} = useTwitterProfileStatus();
  const {data: connectFBStatus, isLoading: isFacebookLoading} =
    useFacebookProfileStatus();
  const {data: connectInstagramStatus} = useInstagramProfileStatus();
  const queryClient = useQueryClient();

  const handleClick = () => {
    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APPID}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URI}&response_type=token&scope=instagram_manage_messages,instagram_basic,pages_manage_metadata,pages_read_engagement,pages_show_list,pages_messaging`;
  };

  const connectInstagram = (accessToken) => {
    apiClient
    .post(
      `/api/instagram/connect-instagram`,
      {
        accessToken,
      },
      {
        headers: {
          token,
        },
      }
    )
    .then(() => {
      queryClient.invalidateQueries("[instagramProfileStatus]");
      navigate({
        ...navigate,
        hash: "",
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Could not add profile");
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = urlParams.get("access_token");
    const long_lived_token = urlParams.get("long_lived_token");

    if (accessToken) {
      const token = long_lived_token ? long_lived_token : accessToken;
      connectInstagram(token);
    }
  }, []);

  const handleLoginSuccess = (response) => {
    apiClient
    .post(`api/facebook/connect-facebook`, {
      facebookId: response.userID,
      accessToken: response.accessToken,
      email: response.email,
      expiresIn: response.expiresIn,
    })
    .then(() => {
      toast.success("profile successfully added!");
      navigate("/facebook/addpage");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Could not add profile");
    });
  };

  const handleLoginFailure = () => {
    toast.error("Login failed");
  };

  const handleTwitterLogin = async () => {
    try {
      const res = await apiClient.get(`api/auth/twitter`);
      if (res.data.success) {
        window.location.href = res.data.url;
      } else {
        window.history.back();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="flex flex-col mt-4 items-center">
      <div className="flex flex-col gap-8 mb-5">
        {/*<div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mt-4 cursor-pointer w-full hover:cursor-not-allowed">*/}
        {/*  {isFacebookLoading ? (*/}
        {/*    <div*/}
        {/*      className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]">*/}
        {/*      <Loader/>*/}
        {/*    </div>*/}
        {/*  ) : !connectFBStatus ? (*/}
        {/*    <FacebookLoginButton*/}
        {/*      onLoginSuccess={handleLoginSuccess}*/}
        {/*      onLoginFailure={handleLoginFailure}*/}
        {/*      className="w-full"*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <>*/}
        {/*      <button*/}
        {/*        className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]"*/}
        {/*        // onClick={() => navigate("/facebook/addpage")}*/}
        {/*      >*/}
        {/*        <div className="flex items-center justify-between w-full ">*/}
        {/*          <FaFacebook className="text-[#34589d]" size={21}/>*/}
        {/*        </div>*/}
        {/*        <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-4 pt-2 pb-8">*/}
        {/*          Facebook connected*/}
        {/*        </p>*/}
        {/*      </button>*/}
        {/*    </>*/}
        {/*  )}*/}

        {/*  /!* Instagram Connection *!/*/}
        {/*  {!connectInstagramStatus ? (*/}
        {/*    // <span onClick={connectFBStatus ? handleClick : undefined}>*/}
        {/*    <span>*/}
        {/*        <Instagram*/}
        {/*          className="w-full"*/}
        {/*          connectFBStatus={connectFBStatus}*/}
        {/*        />*/}
        {/*      </span>*/}
        {/*  ) : (*/}
        {/*    <button*/}
        {/*      className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]"*/}
        {/*      onClick={() => navigate("/instagram/business-profile")}*/}
        {/*    >*/}
        {/*      <div className="flex items-center justify-between w-full ">*/}
        {/*        <img*/}
        {/*          src={instagramIcon}*/}
        {/*          alt="facebook-icon"*/}
        {/*          className="w-5 h-5"*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-4 pt-2 pb-18">*/}
        {/*        Instagram connected*/}
        {/*      </p>*/}
        {/*    </button>*/}
        {/*  )}*/}

        {/*  /!* Twitter Connection *!/*/}
        {/*  {!connectTwitterStatus ? (*/}
        {/*    <TwitterLoginButton*/}
        {/*      className="w-full"*/}
        {/*      handleTwitterLogin={handleTwitterLogin}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <button*/}
        {/*      className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba] "*/}
        {/*      // onClick={() => navigate("/twitter/profiles")}*/}
        {/*    >*/}
        {/*      <div className="flex items-center justify-between w-full">*/}
        {/*        <FaSquareXTwitter size={21} className=""/>*/}
        {/*      </div>*/}
        {/*      <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-4 pt-2 pb-18">*/}
        {/*        Twitter connected*/}
        {/*      </p>*/}
        {/*    </button>*/}
        {/*  )}*/}

        {/*  /!* Linkdin Connection *!/*/}
        {/*  <div*/}
        {/*    className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba] hover:cursor-not-allowed">*/}
        {/*    <div className="flex items-center justify-between w-full">*/}
        {/*      <ImLinkedin2 className="text-[#017bb8]" size={20}/>*/}
        {/*    </div>*/}
        {/*    <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-4 pt-2 pb-18">*/}
        {/*      {!connectLinkedinStatus*/}
        {/*        ? "LinkedIn (Coming Soon)"*/}
        {/*        : "LinkedIn Connected"}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <DesktopDownloadSection/>

      </div>
    </div>
  );
};

export default SocialMediaConnection;
